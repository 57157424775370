import { request } from "@/api"

// 获取手机验证码 【后台规定，登录后不需要传手机号】
export const sendSmsCode = async (data, needLogin = false) => {
  const res = await request.post('/banzhu/common/sms/sendCaptcha', data, true, needLogin)
  return res
}
// 手机验证码修改密码
export const setpwd = async (data) => {
  const res = await request.post('/mall/setpwd', data)
  return res
}
